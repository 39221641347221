<template>
  <div class="institutions">
    <div class="title">机构资料</div>
    <div class="content">
      <div class="l-content">
        <div class="img">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            accept=".jpg,.png"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <div class="mainBody">
          <div class="g_title">主体名称</div>
          <div class="b_title">{{ entityName }}</div>
          <div class="g_title">统一社会信用代码</div>
          <div class="b_title">{{ socialCreditCode }}</div>
          <div class="g_title">机构类型</div>
          <div class="b_title">{{ orgType }}</div>
          <div class="g_title">营业执照</div>
          <div style="color: #156ED0">查看></div>
          <el-image
            style="width: 40px; height: 40px"
            :src="url"
            :preview-src-list="srcList"
          >
          </el-image>
        </div>
      </div>
      <div class="r-content">
        <el-form label-position="top" label-width="80px" :model="form">
          <el-form-item label="机构名称">
            <el-input v-model="form.organizeName" disabled></el-input>
          </el-form-item>
          <el-form-item label="机构简介">
            <el-input type="textarea" v-model="form.recommend"></el-input>
          </el-form-item>
          <el-form-item label="所在城市">
            <el-cascader
              v-model="form.region"
              :options="form.options"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="服务内容">
            <el-checkbox-group v-model="form.type" @change="handleCheck" :max="5">
              <el-checkbox
                v-for="(item, index) in form.serviceMessage"
                :key="index"
                :label="item"
                style="width: 15%"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="机构联系方式">
            <el-input v-model="form.principalPhone" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">保存修改</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '../../../../../network';
export default {
  data () {
    return {
      form: {
        organizeName: '',
        recommend: '',
        region: [],
        options: [],
        principalPhone: '',
        serviceMessage: [],
        type: []
      },
      entityName: '',
      socialCreditCode: '',
      orgType: '',
      licenseImg: '',
      imageUrl: '',
      imgShow: false,
      urls: '',
      url: '',
      srcList: []
    };
  },
  computed:{
     uploadUrl() {
      return `${process.env.VUE_APP_BASEURL}/pcp/user/uploadAvatar`;
    },
  },
  mounted () {
    this.getOrgMessage();
  },
  methods: {
    getOrgMessage () {
      request({
        method: 'POST',
        url: '/pcp/org/getOrgMessage',
        data: {
          orgId: JSON.parse(localStorage.getItem('userinfo')).orgId
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            res.data.data.area.forEach((e) => {
              e.value = e.parent.code;
              e.label = e.parent.name;
              e.children.forEach((v) => {
                v.value = v.code;
                v.label = v.name;
              });
            });
            this.form.options = res.data.data.area;
            const org = res.data.data.org;
            this.form.organizeName = org.organizeName;
            this.form.recommend = org.recommend;
            this.form.principalPhone = org.principalPhone;
            this.entityName = org.entityName;
            this.socialCreditCode = org.socialCreditCode;
            this.orgType = org.orgType;
            this.licenseImg = org.licenseImg;
            this.imageUrl = org.logo;
            this.form.serviceMessage = res.data.data.serviceMessage;
            this.url = org.licenseImg;
            this.srcList.push(org.licenseImg);
            this.form.type = res.data.data.serviceMessage.filter((v) => {
              return v.state === 1;
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSubmit () {
      let str = '';
      const arr = this.form.serviceMessage.filter(
        (item) => this.form.type.indexOf(item) > -1
      );
      arr.forEach((v, i) => {
        if (i === arr.length - 1) {
          str += v.id;
        } else {
          str += v.id + ',';
        }
      });
      if (!/^1[3456789]\d{9}$/.test(this.form.principalPhone)) {
        this.$message.error({
          message: '手机号格式错误'
        });
      } else {
        this.updateOrgMessage(
          this.form.recommend,
          this.form.region[0],
          this.form.region[1],
          str,
          this.form.principalPhone,
          this.urls
        );
      }
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.urls = res.data;
    },
    beforeAvatarUpload (file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whiteList = [ 'jpg', 'png', 'JPG', 'PNG' ];
      const isLt10M = Number(file.size / 1024 / 1024);
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传文件只能是 pdf、png格式');
      }
      if (isLt10M > 10) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return whiteList && isLt10M;
    },
    handleCheck (e) {},
    updateOrgMessage (recommend, prov, city, serviceContents, mobile, url) {
      request({
        method: 'POST',
        url: '/pcp/org/updateOrgMessage',
        data: {
          orgId: JSON.parse(localStorage.getItem('userinfo')).orgId,
          recommend: recommend,
          prov: prov,
          city: city,
          serviceContents: serviceContents,
          mobile: mobile,
          url: url
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            });
            this.getOrgMessage();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    examine () {}
  }
};
</script>

<style lang="less" scoped>
.institutions {
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
  .title {
    color: #126db3;
    font-weight: bold;
    margin-bottom: 40px;
  }
  .content {
    display: flex;
    margin-left: 80px;
    .l-content {
      // .img {
      //   width: 250px;
      //   height: 250px;
      //   border: 1px solid #eee;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   img {
      //     width: 60%;
      //     height: 60%;
      //   }
      // }
      .mainBody {
        margin-top: 40px;
        width: 250px;
        height: 250px;
        border: 1px solid #eee;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        > div {
          margin-left: 10px;
        }
      }
    }
    .r-content {
      margin-left: 60px;
      .el-input {
        width: 400px;
      }
      .el-select {
        width: 100px;
      }
    }
  }
}

.g_title {
  font-size: 14px;
  color: #666;
}
.b_title {
  font-size: 18px;
  color: #000;
  font-weight: bold;
}
/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 250px;
  height: 250px;
  line-height: 250px;
  text-align: center;
}
/deep/.avatar {
  width: 250px;
  height: 250px;
  display: block;
}
</style>
